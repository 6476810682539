<template>
  <el-dialog custom-class="ql-dialog gradeDialog" title="编辑年级" :visible.sync="visible" width="540px">
    <el-form ref="form" :model="formData" :rules="rules" label-width="45px">
      <el-form-item label="年级" size="small">
        <el-input :value="formData.grade" disabled></el-input>
      </el-form-item>
      <el-form-item label="学段" size="small">
        <el-input :value="formData.period" disabled></el-input>
      </el-form-item>
      <el-form-item label="级长" prop="prefectId" size="small">
        <el-select v-model="formData.prefectId" placeholder="在下拉列表中选择一项..." style="width: 100%">
          <el-option
            v-for="item in prefectOptions"
            :key="item.userId"
            :label="item.userName"
            :value="item.userId"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleAdd" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Button, Dialog, Form, FormItem, Input, Select, Option } from 'element-ui';
import { saveGrade } from '@/api/basic';

export default {
  name: 'GradeDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    prefectOptions: Array,
    dialogData: Object,
  },
  data() {
    const validatePrefectId = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请设置级长'));
        return;
      }
      callback();
    };
    return {
      rules: {
        prefectId: [
          {
            validator: validatePrefectId,
            trigger: 'blur',
          },
        ],
      },

      formData: {},
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.formData = this.dialogData;
      }
    },
  },
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  methods: {
    handleAdd() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.lastPrefectId === this.formData.prefectId) {
            this.visible = false;
            return;
          }

          saveGrade({
            gradeId: this.formData.gradeId,
            gradeUserId1: this.formData.prefectId,
          }).then((res) => {
            if (res && res.result === 0) {
              this.$emit('success', this.formData);
            }
          });
        }
        return false;
      });
    },

    handleCancel() {
      this.visible = false;
      this.$refs.form.clearValidate();
    },
  },
};
</script>

<style lang="scss" scoped>
.gradeDialog {
}
</style>
