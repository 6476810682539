// methods
import { getGradePageJson, getGradeEdit } from '@/api/basic';
// components
import { Button, Table, TableColumn } from 'element-ui';
import basicHeader from '@/components/backStageHeader';
import qlPagination from '@/components/qlPagination';
import gradeDialog from './components/gradeDialog';

let prefectMap = new Map();
export default {
  name: 'grade',
  props: {},
  data() {
    return {
      loading: false,
      listData: {
        list: [],
        pageSize: 10,
        pageIndex: 1,
        total: 0,
      },
      visible: false,
      formData: {
        index: -1,
        gradeId: null,
        grade: '',
        period: '',
        lastPrefectId: null,
        prefectId: null,
        prefect: '',
      },
      prefectOptions: [],
    };
  },
  components: {
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    basicHeader,
    qlPagination,
    gradeDialog,
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    notAllowOperate() {
      return this.userInfo.thirdpartySchoolProjectCode !== '';
    },
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    prefectMap = null;
    prefectMap = new Map();
  },
  methods: {
    init() {
      this.getGradePageJson();
    },
    getGradePageJson(reset = false) {
      let { pageIndex, pageSize } = this.listData;

      if (reset) pageIndex = this.listData.pageIndex = 1;

      this.loading = true;

      getGradePageJson({
        pageIndex,
        pageSize,
      })
        .then(
          (res) => {
            let { recordCount, data } = res.result;
            this.listData.total = recordCount;
            this.listData.list = extendData(data || []);
          },
          (err) => console.log(err)
        )
        .finally(() => {
          this.loading = false;
        });

      function extendData(data) {
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          item.period = item.gradeTypeObj ? item.gradeTypeObj.period : null;
          item.userName = item.gradeUser1 ? item.gradeUser1.userName : '';
        }
        return data;
      }
    },

    // 编辑
    async handleEdit(val, idx) {
      if (this.notAllowOperate) return;

      let prefectOptions = [];
      if (prefectMap.has(val.gradeId)) {
        prefectOptions = prefectMap.get(val.gradeId);
      } else {
        const res = await getGradeEdit({
          gradeId: val.gradeId,
        });
        if (res.result && res.result.teacherList) {
          prefectOptions = res.result.teacherList;
          prefectMap.set(val.gradeId, prefectOptions);
        }
      }

      this.prefectOptions = prefectOptions;
      this.formData = {
        index: idx,
        gradeId: val.gradeId,
        grade: val.gradeName,
        period: val.period,
        prefect: val.userName,
        lastPrefectId: val.gradeUserId1,
        prefectId: val.gradeUserId1,
      };
      this.visible = true;
    },

    handleSuccess(data) {
      this.visible = false;
      const item = this.listData.list[data.index];
      item.gradeUserId1 = data.prefectId;
      let prefect = '';
      for (let i = 0; i < this.prefectOptions.length; i++) {
        if (item.gradeUserId1 === this.prefectOptions[i].userId) {
          prefect = this.prefectOptions[i].userName;
        }
      }
      item.userName = prefect;
    },

    handleSizeChange(val) {
      this.listData.pageSize = val;
      this.getGradePageJson(true);
    },
    handleCurrentChange(val) {
      this.listData.pageIndex = val;
      this.getGradePageJson();
    },
  },
};
